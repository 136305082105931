export enum ProjectUseTemplateEnum {
  Source = 'project',
  CopyKpi = 'copyKpi',
  CopyAt = 'copyAt',
  CopyKpiTree = 'copyKpiTree',
  CopyGt = 'copyGt',
}

export interface ProjectUseTemplateFormValues {
  [ProjectUseTemplateEnum.Source]: number;
  [ProjectUseTemplateEnum.CopyKpi]: boolean;
  [ProjectUseTemplateEnum.CopyAt]: boolean;
  [ProjectUseTemplateEnum.CopyGt]: boolean;
}

export interface ProjectUseTemplateResponse {
  projectId: number;
  formValues: any;
}
